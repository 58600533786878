import { api } from "../settings";
import { trackCustomEvent } from "./tracking";

export function apiFetch(url: string, init: Partial<RequestInit>) {
  trackCustomEvent({
    category: "Api",
    action: url.split("?")[0],
  })

  return fetch(api + "/api/" + url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.x
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",

    },
    redirect: "error", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    ...init
  })
}

export function userFetch(method: "GET" | "POST", url: string, jwtToken?: string, body?: string | BodyInit, mimeType?: string) {

  trackCustomEvent({
    category: "Api",
    action: url.split("?")[0],
  })

  return fetch(api + "/api/" + url, {
    method: method, // *GET, POST, PUT, DELETE, etc.x
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      //"Content-Type": mimeType ? mimeType : "application/json",
      "Authorization": "bearer " + jwtToken
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: method === "POST" ? body : undefined,
    redirect: "error", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  })
    .then(a => a.json())
    .catch(x => alert("error" + x?.message));

}