import { ChakraProvider } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import "./simpleLayout.css"
export default function SimpleLayout({
  children,
  width,
}: {
  children: any
  title: string
  location: WindowLocation
  width?: string
}) {
  return (
    <ChakraProvider resetCSS={true}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          verticalAlign: "center",
          padding: "1rem",
          width: "100%",
        }}
      >
        <main>
          <div style={{ display: "flex ", justifyContent: "center" }}>
            <Link to="/" style={{ display: "flex ", justifyContent: "center" }}>
              <img
                src="/header.svg"
                style={{ width: "50% ", maxWidth: "200px" }}
              />
            </Link>
          </div>
          <div
            style={{
              borderRadius: "1.375em",
              marginTop: "2rem",
              padding: "1rem",
              boxShadow: "0 3px 10px 0 rgba(0,0,0,.15)",
              width,
              maxWidth: "800px",
            }}
          >
            {children}
          </div>
        </main>
      </div>
    </ChakraProvider>
  )
}
