import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  theme,
  ThemeProvider,
} from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { Link, navigate } from "gatsby"
import React, { useCallback, useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { decodeToken, useJwt } from "react-jwt"
import SimpleLayout from "../components/simpleLayout"
import UserContext from "../context/userContext"
import { apiFetch } from "../utility/userFetch"

export default function Login({
  location,
  noRedirect,
}: {
  location: WindowLocation
  noRedirect?: boolean
}) {
  const { state, dispatch } = useContext(UserContext)

  const { register, handleSubmit, watch, control } = useForm()
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = function (a: any) {
    if (a.hasSuccess) {
      localStorage.setItem("jwt", a.jwt)
      dispatch({
        ...state,
        jwt: a.jwt,
      })
      const token = decodeToken(a.jwt)

      if (token.likes) {
        localStorage.setItem(
          "likes",
          token.likes || localStorage.getItem("likes")
        )
      }
      if (!noRedirect) {
        if (token.kioskId) {
          navigate("/mutate")
        } else {
          navigate("/")
        }
      }
    } else {
      alert("Wachtwoord niet geldig")
    }
  }

  const login = useCallback(
    (x: any) => {
      setIsLoading(true)
      apiFetch("LoginTrigger", {
        body: JSON.stringify({
          Username: x.username,
          Password: x.password,
        }),
      })
        .then(a => a.json())
        .then(a => {
          handleLogin(a)
        })
        .finally(() => setIsLoading(false))
    },
    [state, dispatch]
  )
  
  const { isExpired } = useJwt(state?.jwt ?? "")

  if (!isExpired) {
    return (
      <SimpleLayout title="Login" location={location}>
        <Heading>Welkom</Heading>
        <Link to="/" style={{ textDecoration: "underline" }}>
          Welkom terug, klik hier om naar de homepage te gaan
        </Link>
      </SimpleLayout>
    )
  }
  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ textAlign: "center" }}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      </ThemeProvider>
    )
  }

  return (
    <SimpleLayout title="Login" location={location}>
      <Flex width="full" align="center" justifyContent="center">
        <Box p={2}>
          <Box textAlign="center">
            <Heading>Login</Heading>
          </Box>
          <Box my={4} textAlign="left">
            <form onSubmit={handleSubmit(login)}>
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input
                  type="email"
                  placeholder="jandevries@dolfijn.nl"
                  name="username"
                  ref={register()}
                  isRequired
                />
              </FormControl>
              <FormControl mt={6}>
                <FormLabel>Wachtwoord</FormLabel>
                <Input
                  type="password"
                  placeholder="*******"
                  name="password"
                  ref={register()}
                  isRequired
                />
              </FormControl>
              <Button width="full" mt={4} type="submit">
                Login
              </Button>
            </form>
          </Box>
        </Box>
      </Flex>
    
      <Divider />
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <Link to="/forget">Wachtwoord vergeten?</Link>
      </div>
    </SimpleLayout>
  )
}
