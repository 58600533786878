export const trackCustomEvent = ({
    category,
    action,
    label,
    value
}: {
    category: string;
    action: string;
    label?: string;
    value?: string;
}) => {
    // @ts-ignore
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
        console.log("trackCustomEvent", category, action);
        // @ts-ignore
        window.gtag('event', action, {
            'event_category': category,
            'event_label': label,
            'value': value
        });
    }
}
